import React from "react";
import { ReactComponent as Logo } from "../../images/logo/Logo.svg";
import { Link } from "react-router-dom";
import "../../styling/MainPage.css";
import FirstServiceImg from "../../images/firstService.png";
import SecondServiceImg from "../../images/secondService.png";
import ThirdServiceImg from "../../images/thirdService.png";
import Footer from "../../components/Footer";

export default function MainPage() {
  const services = [
    {
      text: "Buy and sell excess cut pieces",
      image: FirstServiceImg,
    },
    {
      text: "Find machinery needed for a project.",
      image: SecondServiceImg,
    },
    {
      text: "Buy and sell finished Products",
      image: ThirdServiceImg,
    },
  ];

  const serviceElements = services.map((service, index) => (
    <div className="mainServiceContainer" key={index}>
      <p className="mainServiceText">{service.text}</p>
      <img
        src={service.image}
        alt="Company logo"
        className="serviceaiamg-style"
      />
    </div>
  ));

  return (
    <div
      className="mainParent1"
      style={{
        overflowY: "auto",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="mainHeader">
        <div>
          <Logo className="mainlogo-image" />
        </div>
        <div className="linkNbutton">
          <div>
            <div className="logIn-text">
              <Link to="/login" className="logIn-link">
                Log in
              </Link>
            </div>
          </div>
          <div>
            <Link to="/signup">
              <button className="mainButton">Get Started</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="nextDiv">
        <div className="titleContainer">
          <p className="titleText">
            How you manage your{" "}
            <span style={{ fontFamily: "PoppinsBold" }}>raw material</span> and{" "}
            <span style={{ color: "#fd9340", fontFamily: "PoppinsBold" }}>
              waste{" "}
            </span>
            matters? <br></br>
            <span className="mobile-font-size">Do it together with </span>
            <span style={{ color: "#fd9340", fontFamily: "PoppinsBold" }}>
              U
            </span>
            <span style={{ fontFamily: "PoppinsBold" }}>EXCESS.</span>
          </p>
        </div>
      </div>
      <div className="mobileButtonDiv">
        <Link to="/signup">
          <button className="mainblackButton ">Get Started</button>
        </Link>
      </div>
      <div className="MainImgContainer">
        <iframe
          width="100%"
          height="100%"
          frameBorder="0"
          allowFullScreen
          src="https://www.youtube.com/embed/qnMTRPJOA-M"
        ></iframe>
      </div>

      <div className="aboutApp">
        <p className="aboutAppTextHeading">
          What is{" "}
          <span style={{ fontFamily: "PoppinsBold" }} className="uexcessText">
            UEXCESS?
          </span>
        </p>
        <p className="aboutAppTextContent">
          Uxexcess is your platform for efficient management of surplus raw
          materials.
        </p>
      </div>
      <div className="serviceContainer">
        <p className="serviceText">Services we offer</p>
      </div>
      <div className="ImgAndDescContainer">{serviceElements}</div>
      <div className="secondLoginContainer">
        <p className="login-text-style">Login to enjoy our services</p>
        <div className="buttondiv">
          <Link to="/signup">
            <button className="mainblackButton mobile-button">
              Get Started
            </button>
          </Link>
        </div>
      </div>
      <div className="footerPadding"></div>
      <Footer />
    </div>
  );
}
